<template>

    <div class="popup active">

		<div class="notice_popup prj_itv pass hr">
                <div class="close">
                    <img src="/images/close.png" alt="닫기" @click="$emit('close', false)"/>
                </div>
                <div class="tit">하이프로 매니저 변경</div>
                <div class="prj_name">프로젝트명 '{{projectNm}}'</div>
                <div class="qs"><span class="name">{{currAdmMgrMberNm}}({{currAdmMgrLoginId}})</span> 님을 <div class="change_name"> <span class="name">{{admMgrMberNm}}({{admMgrLoginId}})</span></div> 님으로<br/>변경하시겠습니까?</div>
                <div class="select-btn">
                    <div class="btn" @click="$emit('close', false)">다른 매니저 선택</div>
                    <div class="btn" @click="save()">네, 변경하겠습니다!</div>
                </div>
		</div>
    </div>

</template>

<script>

export default {

	components : {		
	},

	props: ['param'],

	data() {
		return {
			projectSeq : this.param.projectSeq,			
			projectNm : this.param.projectNm,
			
			currAdmMgrMberNm : this.param.currAdmMgrMberNm,
			currAdmMgrLoginId : this.param.currAdmMgrLoginId,

			admMgrMberSeq : this.param.admMgrMberSeq,		// 변경할 매니저
			admMgrMberNm : this.param.admMgrMberNm,
			admMgrLoginId : this.param.admMgrLoginId,

		};
	},
	beforeCreate() {
		// console.log('beforeCreate');
	},
	created() {
		// console.log('created');
	},
	beforeMount() {
		// console.log('beforeMount');		
	},
	mounted() {
		// console.log('mounted');
	},
	beforeUpdate() {
		// console.log('beforeUpdate');
	},
	updated() {
		// console.log('update');
	},
	beforeDestroy() {
		// console.log('beforeDestroy');
	},
	destroyed() {
		// console.log('destroyed');
	},

	methods: {

		save() {

			var input = {
				projectSeq	: this.projectSeq,
				admMgrMberSeq	: this.admMgrMberSeq
			}

			this.$.httpPost('/api/prj/adm/updateProjectAgent', input)
				.then(() => {
					this.$emit('close', true);
				})
				.catch(err => {
					// console.log('============================='); 
					// console.log(err);
					// console.log(err.response);
					// console.log('============================='); 

					alert(err.response.data.error_description);
				});	
					

		}
	},
};
</script>
